import React from 'react';
import './soundWave.css'; // Estilos CSS

const WaitingResponse: React.FC = () => {
  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  // Datos de ejemplo: amplitudes de la onda de sonido
  const amplitudes = [20, 20, 20]
  return (
    <div className="wave-container">
      {amplitudes.map((amp: any, index: any) => (
        <div key={index} className="wave-bar" style={{ height: amp, marginRight: '2px' }} />
      ))}
    </div>
  );
};

export default WaitingResponse;
