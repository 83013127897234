
// import { useState } from "react";

// export const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

// const isMediaRecorderSupported = () => {
//     return typeof MediaRecorder !== 'undefined';
//   };

// let mediaRecorder: MediaRecorder | null = null;

// const startRecordingWithMediaRecorder = async (): Promise<Blob> => {
//     return new Promise<Blob>(async (resolve, reject) => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             mediaRecorder = new MediaRecorder(stream);
//             const chunks: Blob[] = [];

//             mediaRecorder.ondataavailable = (event: BlobEvent) => {
//                 chunks.push(event.data);
//             };

//             mediaRecorder.onstop = () => {
//                 const blob = new Blob(chunks, { type: 'audio/mp4' });
//                 setAudioBlob(blob);
//                 resolve(blob);
//             };

//             mediaRecorder.onerror = (event: any) => {
//                 reject(new Error(`Recording error: ${event.error?.name || 'unknown error'}`));
//             };

//             mediaRecorder.start();
//         } catch (error: any) {
//             reject(new Error(`Failed to access the microphone: ${error.message}`));
//         }
//     });
// };

// const stopRecordingWithMediaRecorder = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//         mediaRecorder.stop();
//     }
// };

// let audioContext: AudioContext | null = null;
// let processorNode: ScriptProcessorNode | null = null;
// let mediaStreamSource: MediaStreamAudioSourceNode | null = null;
// let audioData: Float32Array[] = [];

// const startRecordingWithScriptProcessor = async () => {
//   try {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     audioContext = new AudioContext();
//     processorNode = audioContext.createScriptProcessor(4096, 1, 1);
//     mediaStreamSource = audioContext.createMediaStreamSource(stream);

//     processorNode.onaudioprocess = (event) => {
//       const inputBuffer = event.inputBuffer.getChannelData(0);
//       audioData.push(new Float32Array(inputBuffer)); // Almacena los datos de audio
//     };

//     mediaStreamSource.connect(processorNode);
//     processorNode.connect(audioContext.destination);  // Evita que el audio se reproduzca

//     console.log('Recording started with ScriptProcessor');
//   } catch (err) {
//     console.error('Error accessing microphone:', err);
//   }
// };

// const stopRecordingWithScriptProcessor = () => {
//   if (processorNode && audioContext) {
//     processorNode.disconnect();
//     mediaStreamSource?.disconnect();
//     audioContext.close().then(() => {
//       console.log('Recording stopped with ScriptProcessor');
//       const buffer = mergeBuffers(audioData);  // Combina los arrays de audio
//       const audioBlob = sendToWorker(buffer);  // Envia los datos al worker para procesamiento (MP3, WAV, OGG)
//       audioData = [];
//       return audioBlob;
//     });
//   }
// };

// const mergeBuffers = (audioData: Float32Array[]) => {
//   const length = audioData.reduce((acc, buffer) => acc + buffer.length, 0);
//   const result = new Float32Array(length);
//   let offset = 0;
//   for (const buffer of audioData) {
//     result.set(buffer, offset);
//     offset += buffer.length;
//   }
//   return result.buffer;  // Retorna un ArrayBuffer para los workers
// };

// const mp3Worker = new Worker(new URL('./workers/encoder-mp3-worker.js', import.meta.url), { type: 'module' });

// const sendToWorker = (buffer: ArrayBuffer) => {
//     // Usamos el worker de MP3
//     mp3Worker.postMessage(['init', { baseUrl: window.location.origin, sampleRate: 44100 }]); // Puedes ajustar el sampleRate según sea necesario
//     mp3Worker.postMessage(['encode', buffer]);
//     mp3Worker.postMessage(['dump']);
  
//     mp3Worker.onmessage = (e) => {
//       const mp3Data = e.data;
//       const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });
//       const url = URL.createObjectURL(mp3Blob);
//       console.log('MP3 URL:', url);
//       return mp3Blob;
//       // Aquí puedes usar el URL para descargar el archivo o enviarlo a un servidor
//     };
//   };

//   export const recordAudio = () => {
//     if (isMediaRecorderSupported()) {
//       startRecordingWithMediaRecorder();
//     } else {
//       startRecordingWithScriptProcessor();
//     }
//   };
  
//   export const stopRecording = () => {
//     if (isMediaRecorderSupported()) {
//       stopRecordingWithMediaRecorder();
//     } else {
//       stopRecordingWithScriptProcessor();
//     }
//   };

// F U N C I O N A L

// let mediaRecorder: MediaRecorder | null = null;

// export const recordAudio = async (): Promise<Blob> => {
//     return new Promise<Blob>(async (resolve, reject) => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//             mediaRecorder = new MediaRecorder(stream);
//             const chunks: Blob[] = [];

//             mediaRecorder.ondataavailable = (event: BlobEvent) => {
//                 chunks.push(event.data);
//             };

//             mediaRecorder.onstop = () => {
//                 const blob = new Blob(chunks, { type: 'audio/wav' });
//                 resolve(blob);
//             };

//             mediaRecorder.onerror = (event: any) => {
//                 reject(new Error(`Recording error: ${event.error?.name || 'unknown error'}`));
//             };

//             mediaRecorder.start();
//         } catch (error: any) {
//             reject(new Error(`Failed to access the microphone: ${error.message}`));
//         }
//     });
// };

// export const stopRecording = () => {
//     if (mediaRecorder && mediaRecorder.state === 'recording') {
//         mediaRecorder.stop();
//     }
// };

// const MicRecorder = require('mic-recorder-to-mp3');
// let recorder: any;
// let audioChunks: Blob[] = [];
// let recordings: Blob[] = [];  // Array para almacenar fragmentos de audio
// let isRecording = false;
// let startTime: number | null = null;

// export const recordAudio = async () => {
//     try {
//         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//         recorder = new MicRecorder({ bitRate: 64 });
//         isRecording = true;
//         await startRecording();

//         // Verifica cada segundo si el audio ha alcanzado los 30 segundos
//         const checkDuration = setInterval(() => {
//             if (!isRecording) {
//                 clearInterval(checkDuration);
//                 return;
//             }

//             const currentTime = Date.now();
//             if (startTime && (currentTime - startTime >= 30000)) {
//                 // Si la duración es mayor o igual a 30 segundos, se detiene y empieza otra grabación
//                 console.log('Audio de 30 segundos alcanzado, cortando...');
//                 stopAndStartNewRecording();
//             }
//         }, 1000);

//     } catch (error: any) {
//         console.log(error);
//     }
// };

// // Función para iniciar una nueva grabación
// const startRecording = async () => {
//     try {
//         startTime = Date.now();
//         await recorder.start();
//     } catch (error) {
//         console.error('Error al iniciar la grabación:', error);
//     }
// };

// // Función para detener la grabación y empezar una nueva
// const stopAndStartNewRecording = async () => {
//     try {
//         const result = await recorder.stop().getMp3();
//         const [buffer, blob] = result;
//         recordings.push(blob);  // Agrega el fragmento al array de grabaciones

//         // Inicia una nueva grabación
//         recorder = new MicRecorder({ bitRate: 64 });
//         await startRecording();
//     } catch (error) {
//         console.error('Error al detener y reiniciar la grabación:', error);
//     }
// };

// // Función para detener completamente la grabación y unir los fragmentos
// export const stopRecording = async (): Promise<Blob> => {
//     return new Promise<Blob>(async (resolve, reject) => {
//         try {
//             isRecording = false;
//             const result = await recorder.stop().getMp3();
//             const [buffer, blob] = result;
//             recordings.push(blob);  // Añade el último fragmento de grabación

//             // Unir todas las grabaciones en un solo Blob
//             const fullAudioBlob = new Blob(recordings, { type: 'audio/mp3' });
//             resolve(fullAudioBlob);

//         } catch (error: any) {
//             reject(new Error(`Failed to stop the recording: ${error.message}`));
//         }
//     });
// };

// // export const recordAudio = async () => {
// //         try {
// //             const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
// //             recorder = new MicRecorder({bitRate: 64});
// //             await recorder.start();
// //         } catch (error: any) {
// //             console.log(error);
// //         }
// // }

// export const pauseRecording = async () => {
//     if (recorder) {
//         try {
//             const [buffer, blob] = await recorder.stop().getMp3(); // Usar async/await
//             const file = new File(buffer, 'me-at-thevoice.wav', {
//                 type: 'audio/wav',
//                 lastModified: Date.now()
//             });
//             console.log("Recording paused");

//             // Almacenar el fragmento grabado
//             audioChunks.push(file);

//             console.log("Audio chunk stored");

//         } catch (e: any) {
//             console.error("Error while stopping recording:", e);
//         }
//     }
// };

// export const resumeRecording = async () => {
//     if (recorder) {
//         try {
//             recorder = new MicRecorder({ bitRate: 128 });
//             await recorder.start(); // Iniciar nuevamente
//             console.log("Recording resumed");
//         } catch (e: any) {
//             console.error("Error resuming recording:", e);
//         }
//     }
// };

// export const stopAndCombineAudio = async () : Promise<Blob> => {
//     return new Promise<Blob>(async (resolve, reject) => {
//         try {
//             console.log("Audio stopped and combining");
//             console.log(audioChunks.length);
//             const combinedBlob = new Blob(audioChunks, { type: 'audio/wav' });
//             const file = new File([combinedBlob], 'combined-audio.wav', {
//                 type: 'audio/wav',
//                 lastModified: Date.now()
//             });
//             console.log("Audio done");
//             resolve(file);
//         } catch (e: any) {
//             reject(new Error("Error combining audio chunks"));
//         }
        
//     })
// };

// export const resetAudioChunks = () => {
//     audioChunks = [];
//     console.log("Chunks reseted");
// }

// // export const stopRecording = async () : Promise<Blob> => {
// //     return new Promise<Blob>(async (resolve, reject) => {
// //         try {
// //         recorder
// //         .stop()
// //         .getMp3().then((buffer: any, blob: any) => {
// //             const file = new File(buffer, 'me-at-thevoice.mp3', {
// //             type: 'audio/mp3',
// //             lastModified: Date.now()
// //             });
// //             console.log("Size of the file: " + file.size / 1000000);
// //             resolve(file);
// //         }).catch((e: any) => {
// //             alert('We could not retrieve your message');
// //             console.log(e);
// //         });
// //         } catch (error: any) {
// //             reject(new Error(`Failed to access the microphone: ${error.message}`));
// //         }
// //     })
// // }

const MicRecorder = require('mic-recorder-to-mp3');

let recorder: any;
let recordings: Blob[] = [];  // Fragmentos de audio grabados
let isRecording = false;
let isPaused = false;
let startTime: number | null = null;
let currentDuration: number = 0; // Duración actual del fragmento grabado

export const recordAudio = async () => {
    // Reiniciar variables para una nueva grabación
    recordings = [];  // Vaciar los fragmentos anteriores
    isRecording = false;
    isPaused = false;
    currentDuration = 0;

    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        recorder = new MicRecorder({ bitRate: 64 });
        isRecording = true;
        await startRecording();

        // Verifica cada segundo si el audio ha alcanzado los 30 segundos
        const checkDuration = setInterval(() => {
            if (!isRecording || isPaused) {
                clearInterval(checkDuration);
                return;
            }

            const currentTime = Date.now();
            const elapsed = currentTime - (startTime || 0) + currentDuration;
            if (elapsed >= 30000) {
                // Si ha grabado 30 segundos o más, corta el fragmento y empieza uno nuevo
                console.log('30 segundos alcanzados, cortando...');
                stopAndStartNewRecording();
            }
        }, 1000);

    } catch (error: any) {
        console.log(error);
    }
};

// Función para iniciar una nueva grabación
const startRecording = async () => {
    try {
        startTime = Date.now();
        await recorder.start();
    } catch (error) {
        console.error('Error al iniciar la grabación:', error);
    }
};

// Función para detener la grabación actual y empezar una nueva
const stopAndStartNewRecording = async () => {
    try {
        const result = await recorder.stop().getMp3();
        const [buffer, blob] = result;
        recordings.push(blob);  // Almacena el fragmento grabado

        currentDuration = 0;  // Reinicia el contador de duración
        recorder = new MicRecorder({ bitRate: 64 });  // Crea un nuevo grabador
        await startRecording();
    } catch (error) {
        console.error('Error al detener y reiniciar la grabación:', error);
    }
};

// Función para pausar la grabación
export const pauseRecording = async () => {
    if (recorder && isRecording && !isPaused) {
        try {
            const [buffer, blob] = await recorder.stop().getMp3();
            recordings.push(blob);  // Almacena el fragmento grabado
            currentDuration += Date.now() - (startTime || 0); // Almacena el tiempo grabado antes de la pausa
            console.log('Grabación pausada');
            isPaused = true;
        } catch (e: any) {
            console.error("Error al pausar la grabación:", e);
        }
    }
};

// Función para reanudar la grabación
export const resumeRecording = async () => {
    if (recorder && isPaused) {
        try {
            recorder = new MicRecorder({ bitRate: 64 });
            await recorder.start();  // Iniciar nuevamente
            startTime = Date.now();  // Reinicia el cronómetro
            isPaused = false;
            console.log('Grabación reanudada');
        } catch (e: any) {
            console.error("Error al reanudar la grabación:", e);
        }
    }
};

// Función para detener completamente la grabación y unir los fragmentos
export const stopRecording = async (): Promise<Blob> => {
    return new Promise<Blob>(async (resolve, reject) => {
        try {
            isRecording = false;
            if (!isPaused) {
                const result = await recorder.stop().getMp3();
                const [buffer, blob] = result;
                recordings.push(blob);  // Añade el último fragmento de grabación
            }

            // Unir todas las grabaciones en un solo Blob
            const fullAudioBlob = new Blob(recordings, { type: 'audio/mp3' });
            resolve(fullAudioBlob);
        } catch (error: any) {
            reject(new Error(`Failed to stop the recording: ${error.message}`));
        }
    });
};

// Función para restablecer los fragmentos de audio
export const resetAudioChunks = () => {
    recordings = [];  // Vacía el array de grabaciones
    console.log("Fragmentos de audio restablecidos");
};