import axios from 'axios';

export const textToSpeech = async (text: string): Promise<Blob | null> => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/audio/speech',
      {
        model: "tts-1",
        voice: "nova",
        input: text,
        response_format: "wav"
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // Importante para obtener el audio como Blob
      }
    );
    const audioBlob = new Blob([response.data], {type: 'audio/wav'});
    console.log("MIME Type: ", response.data.type);
    return audioBlob;
  } catch (error) {
    console.error('Error fetching speech audio:', error);
    return null;
  }
};