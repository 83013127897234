// L I B R A R I E S 
import { Box, Button, Fade, Menu, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { ArrowUpwardRounded } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

// C O M P O N E N T S
import { Message } from '../models/Message';
import { User } from '../models/User';

// A S S E T S
import { Dehaze } from '@mui/icons-material';
import Bot from '../assets/bot.png'
import '../App.css';

function Grammar() {
  const location = useLocation();
  const language: string = location.state?.language;
  const firstMessage = language === 'english' ? [{role: 'user', content: 'Estoy aprendiendo Inglés. Mi idioma natal es español.Tengo una duda gramatical del idioma inglés. Puedes explicarme como si fueras profesora de inglés para hablantes de español unas dudas?.'}]
    : language === 'german' ? [{role: 'user', content: 'Estoy aprendiendo alemán. Mi idioma natal es español. Tengo una duda gramatical del idioma alemán. Puedes explicarme como si fueras profesora de alemán para hablantes de español unas dudas?'}]
    : [{role: 'user', content: "Estoy aprendiendo francés. Mi idioma natal es español.Tengo una duda gramatical del idioma francés. Puedes explicarme como si fueras profesora de francés para hablantes de español unas dudas?"}];
  const [messages, setMessages] = useState<Message[]>(firstMessage);
  const [showSendIcon, setShowSendIcon] = useState(false);
  const [response, setResponse] = useState<string>('');
  const [input, setInput] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const currentUser: User = location.state?.currentUser;

    const handleSubmit = async (e: any) => {

    const userMessage = { role: 'user', content: input };
    const newMessages = [
    ...messages,
    userMessage
    ];

    setMessages(newMessages);

    setInput(''); 

    try {
        const res = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
            model: 'gpt-4o-2024-08-06',
            messages: newMessages
        },
        {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
            }
        }
        );

        const responseData = res.data;
        const assistantResponse = responseData.choices[0].message.content;

        setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: assistantResponse }
        ]);
        setResponse(assistantResponse);
        setInput(''); 
        setShowSendIcon(false);
        console.log(messages);

    } catch (error) {
        console.error('Error fetching data from OpenAI API', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4o-2024-08-06',
            messages: [
              ...messages]
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
            }
          }
        );
  
        const responseData = res.data;
        const assistantResponse = responseData.choices[0].message.content;
  
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: assistantResponse }
        ]);
        setResponse(assistantResponse);
        console.log(messages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); 
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  if (!currentUser || !currentUser.isAuthenticated) {
    return <Navigate to="/login" />
  }

  const handleChange = (event: any) => {
    setInput(event.target.value);
    setShowSendIcon(event.target.value.length > 0);
  }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePracticeConversation = () => {
        setAnchorEl(null);
        navigate('/chat', {state: {currentUser, language: language}})
    };

    const handleHome = () => {
      setAnchorEl(null);
      navigate('/home', {state: {currentUser, language: language}})
    };

    const handleGrammar = () => {
        setAnchorEl(null);
        navigate('/grammar', {state: {currentUser, language: language}})
    };

    const handleTaskReviewer = () => {
      setAnchorEl(null);
      navigate('/taskreviewer', {state: {currentUser, language: language}})
      };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const messagesLimit = 10;

    const responsiveness = (small: any, medium: any, large: any) => {
      return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
    }

    const formatResponse = (response: string): string => {
      let formatted = response;
    
      // Negritas para los encabezados de secciones (numeradas con **)
      formatted = formatted.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
      // Listas de puntos (usamos guiones para indicar los elementos)
      formatted = formatted.replace(/-\s(.*?)\n/g, '<li>$1</li>');
      formatted = formatted.replace(/<\/li>\n<li>/g, '</li><li>'); // Para asegurar que las listas se mantengan
    
      // Agregar <ul> alrededor de las listas
      formatted = formatted.replace(/<li>(.*?)<\/li>/g, '<ul><li>$1</li></ul>');
    
      // Párrafos (separar bloques de texto por doble salto de línea)
      formatted = formatted.replace(/\n\n/g, '</p><p>');

      formatted = formatted.replace(/#/g, '')

      // Reemplazar doble salto de línea con cierre y apertura de párrafos
      formatted = formatted.replace(/\\n\\n/g, '</p><p>');  // Para el caso de \\n

      // Eliminar saltos de línea individuales
      formatted = formatted.replace(/\\n/g, '');  // Para \\n
      formatted = formatted.replace(/\n/g, '');   // Para \n

      // Reemplazos de nombres y otros caracteres
      formatted = formatted.replace(/---/g, '');
      formatted = formatted.replace(/ChatGPT|Assistant|Asistente|Assistentin|Assistent|assistante|assistant|assistentin|assistent/g, 'Retzia');
      formatted = formatted.replace(/OpenAI|openai|openAI|Openai/g, 'Retz');
    
      return `<p>${formatted}</p>`;
    };

    const isLimitReached = Math.floor(messages.length / 2)  - 1 >= messagesLimit;
    
    const isFeedbackShown = Math.floor(messages.length / 2) - 1 >= 3;
    
    const suggestionsStyle = { backgroundColor: '#f8f8f8', color: 'black', padding: '1% 2%', borderRadius: '8px', fontSize: responsiveness('10px', '14px', '16px'), maxWidth: responsiveness('20%', '30%', '40%') };

 
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
        <nav style={{
            backgroundColor: '#514DD8',
            paddingLeft: '5%'
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', paddingTop: '0.75%', paddingBottom: '0.75%'}}>
                <Button sx={{ flex: 1 }} onClick={handleClick}>
                    <Dehaze fontSize='large' style={{ color: 'white' }} />
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handleHome}>INICIO</MenuItem>
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handlePracticeConversation}>QUIERO PRACTICAR MI CONVERSACIÓN</MenuItem>
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handleGrammar}>TENGO UNA DUDA DE GRAMÁTICA O VOCABULARIO</MenuItem>
                    <MenuItem sx={{fontSize: responsiveness('12px', '14px', '16px')}} onClick={handleTaskReviewer}>QUIERO REALIZAR UNA ACTIVIDAD DEL CURSO</MenuItem>
                </Menu>
                <div style={{
                  flex: 20,
                  fontFamily: 'PublicSans-Bold', 
                  fontSize: responsiveness('20px', '26px', '30px'), 
                  display: 'flex', 
                  color: 'white', 
                  alignItems: 'center', 
                  justifyContent: 'center'
                }}>
                  Retzia <img src={Bot} alt='' width={responsiveness(50, 60, 70)} />                
                </div>
            </div>
        </nav>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'scroll'
      }}>
        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                marginTop: '2%',
                marginBottom: '2%'
            }}>
                <Typography sx={{ ...suggestionsStyle }}>Ayúdame a entender<br/>{language === 'english' ? 'el uso del verb to be.' : language === 'german' ? 'el Nominativ y Akkusativ' : 'Les articles définis'}</Typography>
                <Typography sx={{ ...suggestionsStyle }}>Cómo se dice<br/>Quiero comer pizza.</Typography>
                <Typography sx={{ ...suggestionsStyle }}>Ponme ejercicios para<br/>{language === 'english' ? 'repasar el uso de -ing.' : 'repasar los pronombres.'}</Typography>
                <Typography sx={{ ...suggestionsStyle }}>Corrige este ejercicio<br/>"..............................."</Typography>
            </Box>
            {messages.map((message, index) => {
          return (
              <Box
              sx={{ display: 'flex', justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start', marginBottom: '1%'}}
              key={index}
              mb={1}
          >
              <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    bgcolor: message.role === 'user' ? '#1e8efc' : '#cccaca',
                    color: message.role === 'user' ? 'white' : 'black',
                    maxWidth: '70%',
                    borderRadius: '15px',
                    position: 'relative',
                    marginRight: responsiveness('2%', '2%', '2%'),
                    marginLeft: responsiveness('2%', '2%', '2%'),
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        width: 0,
                        height: 0,
                        border: '10px solid transparent',
                        borderTopColor: message.role === 'user' ? '#1e8efc' : '#cccaca',
                        top: '50%',
                        transform: 'translateY(-10%)',
                        right: message.role === 'user' ? '-10px' : 'auto',
                        left: message.role === 'user' ? 'auto' : '-10px',
                    },
                }}
              >
              {index === 0 ? (
                <Typography sx={{ fontSize: responsiveness('14px', '14px', '16px') }}>
                  {'Tengo una duda sobre...'}
                </Typography>
              ) : null}

              {message.role === 'assistant' ? (
                <Typography
                  sx={{ fontSize: responsiveness('14px', '14px', '16px') }}
                  dangerouslySetInnerHTML={{ __html: formatResponse(message.content) }}
                />
              ) 
              : ( index !== 0 &&
                <Typography sx={{ fontSize: responsiveness('14px', '14px', '16px'), whiteSpace: 'pre-wrap' }}>
                  {message.content}
                </Typography>
              )}
              </Paper>
          </Box>
            ); })}
            <div ref={messagesEndRef} />
      </Box>
      { isFeedbackShown && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', fontSize: responsiveness('12px', '14px', '14px'), marginLeft: '-3%'}}><a href="https://retz.com.mx/feedback-retzia/" target='_blank' rel='noreferrer'>Compártenos tu experiencia en esta conversación.</a></div>
      )}
      <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            backgroundColor: '#f8f8f8', 
            justifySelf: 'end',
            margin: '1% 2%',
            borderRadius: responsiveness('8px', '10px', '16px'),
            justifyContent: Math.floor(messages.length / 2) - 1  >= messagesLimit ? 'space-between' : ''
            }}>
            <TextField 
            onKeyDown={(event: any) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleSubmit(input);
              }
            }}
            disabled={Math.floor(messages.length / 2) - 1  >= messagesLimit}
            value={input}
            InputProps={{ disableUnderline: true }} 
            variant='standard' 
            sx={{ width: isLimitReached ? '0%' : '95%', padding: '1%', borderRadius: '16px'}} 
            onChange={handleChange}
            placeholder= 'Haz clic en el micrófono o escribe aquí.'
            multiline
            minRows={1} 
            maxRows={6}> 
            </TextField>
            { isLimitReached && (
              <Typography
              sx={{
                fontSize: responsiveness('12px', '18px', '24px'),
                alignContent: 'center',
                justifyContent: 'center'
              }}>
                Tus interacciones del día han finalizado, por favor, regresa mañana.
              </Typography>
            )}
            <Typography sx={{ alignContent: 'center', fontSize: responsiveness('12px', '14px', '16px'), marginRight: isLimitReached ? '2%' : '1%' }}>{Math.floor(messages.length / 2) - 1}/{messagesLimit}</Typography>
            { showSendIcon && (
            <Fade in={showSendIcon} timeout={500}>
              <Button disabled={isLimitReached} onClick={handleSubmit}>
                <ArrowUpwardRounded style={{ color: 'black'}} fontSize='large' />
              </Button>
            </Fade>
            )}
          </Box>
    </div>
  );
};

export default Grammar;
