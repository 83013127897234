import React from 'react';
import './soundWave.css'; // Estilos CSS

const SoundWave: React.FC = () => {
  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  // Datos de ejemplo: amplitudes de la onda de sonido
  const amplitudes = responsiveness([10, 30, 20, 40, 25, 35, 10, 50, 40, 30, 20, 20, 40, 50, 25, 35],
     [10, 30, 20, 40, 25, 35, 10, 50, 40, 30, 20, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35, 10, 50, 40, 30],
    [10, 30, 20, 40, 25, 35, 10, 50, 40, 30, 20, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35, 10, 50, 40, 30, 20, 30, 20, 40, 50, 25, 35]);
  return (
    <div className="wave-container">
      {amplitudes.map((amp: any, index: any) => (
        <div key={index} className="wave-bar" style={{ height: amp, marginRight: '2px' }} />
      ))}
    </div>
  );
};

export default SoundWave;
