// L I B R A R I E S
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import React from 'react';

// C O M P O N E N T S
import { Box, Button } from '@mui/material';
import { User } from '../models/User';
import Navbar from './navbar';

// A S S E T S
import Bot from '../assets/bot.png'
import '../App.css';

function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser: User = location.state?.currentUser;

  if (!currentUser || !currentUser.isAuthenticated) {
    return <Navigate to="/login" />
  }
  
  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  const buttonsStyle = { backgroundColor: '#514DD8', color: 'white', borderRadius: '8px', padding: responsiveness('5% 10%', '5% 10%', '5% 10%'), marginBottom: responsiveness('10%', '5%', '3%'), '&:hover': { color: '#514DD8', border: '#514DD8 solid 2px' } };
 
  return (
    <div>
      <Navbar />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: responsiveness('5%', '3%', '1%')
      }}>
        <Box sx={{ width: '80%'}}>
          <p style={{ fontFamily: 'PublicSans-Bold', fontSize: responsiveness('25px', '30px', '40px')}}>Hola {currentUser.name}, soy Retzia, <img src={Bot} alt='' width={responsiveness(80, 100, 130)} height={responsiveness(60, 70, 90)} /> tu profesora virtual.</p>
          <p style={{ marginLeft: '1%', fontFamily: 'PublicSans-Normal', fontSize: responsiveness('20 px', '25px', '30px')}}>Haz clic en el idioma que desees practicar.</p>
          <Box sx={{ marginLeft: '1%', display: 'flex', flexDirection: 'column', width: responsiveness('60%', '50%', '40%'), marginTop: '3%'}}>
            <Button onClick={() => navigate('/language', {state: { language: 'english', currentUser }})} sx={{...buttonsStyle}}>INGLÉS</Button>
            <Button onClick={() => navigate('/language', {state: { language: 'german', currentUser }})} sx={{...buttonsStyle}}>ALEMÁN</Button>
            <Button onClick={() => navigate('/language', {state: { language: 'french', currentUser }})} sx={{...buttonsStyle}}>FRANCÉS</Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '1%'}}>
      </Box>
    </div>
  );
};

export default Home;
