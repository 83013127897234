// L I B R A R I E S
import React from 'react';

// C O M P O N E N T S 
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';

// A S S E T S
import './App.css';   
import LanguageHome from './components/LanguageHome';
import Grammar from './components/Grammar';
import Chat from './components/Chat';
import TaskReview from './components/TaskReview';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login/french" element={<Navigate to="/login" state={{language: 'french'}} />} />
        <Route path="/login/german" element={<Navigate to="/login" state={{language: 'german'}} />} />
        <Route path="/login/english" element={<Navigate to="/login" state={{language: 'english'}} />} />
        <Route path="/language" element={<LanguageHome />} />
        <Route path="/chat" element={<Chat />}/>
        <Route path="/grammar" element={<Grammar />}/>
        <Route path="/taskreviewer" element={<TaskReview />}/>
      </Routes>
    </Router>
  );
}

export default App;
    