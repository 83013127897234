// L I B R A R I E S
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';

// C O M P O N E N T S
import { User } from '../models/User';
import Navbar from './navbar';

// A S S E T S
import Bot from '../assets/bot.png'
import '../App.css';

function Login() {
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [showError, setShowEror] = useState<boolean>(false);
const navigate = useNavigate();
const location = useLocation();
const language = location.state?.language;


const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const authConfirmationUrl = 'https://retz.mx/login/token.php?username=' + username + '&password=' + password + '&service=moodle_mobile_app';
      const getUserInfoUrl = "https://retz.mx/webservice/rest/server.php?wstoken=8e362276226c310b5e562a5095ea7b0a&wsfunction=core_user_get_users&moodlewsrestformat=json&criteria[0][key]=username&criteria[0][value]=" + username;
      const authConfirmationResponse = await axios.post(authConfirmationUrl);
      const getUserInfoResponse = await axios.post(getUserInfoUrl);

      if(getUserInfoResponse.data.users.length > 0 && authConfirmationResponse.data.token !== undefined) {
        const currentUser: User = {
            token: authConfirmationResponse.data.token,
            name: getUserInfoResponse.data.users[0].firstname,
            isAuthenticated: true
        };
        language === 'english' ? navigate('/language', {state: {language: 'english', currentUser}})
        : language === 'german' ? navigate('/language', {state: {language: 'german', currentUser}})
        : language === 'french' ? navigate('/language', {state: {language: 'french', currentUser}})
        : navigate('/home', {state: {currentUser} });
      } else {
        setShowEror(true);
      }
    } catch (error) {
        console.log(error);
      }
  };

  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  return (
    <div>
      <Navbar />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '5%'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <p style={{ fontFamily: 'PublicSans-Bold', fontSize: responsiveness('20px', '32px', '48px')}}>Iniciar Sesión en Retzia</p>
          <img src={Bot} alt='' width={responsiveness(80, 100, 150)} height={responsiveness(60, 80, 100)} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: responsiveness('5%', '3%', '1%')}}>
          <TextField onChange={(e) => setUsername(e.target.value)} sx={{ width: responsiveness('60%', '40%', '30%'), marginBottom: responsiveness('8%', '5%', '3%')}} InputLabelProps={{ shrink: true }} id='usuario' label='Usuario' variant='outlined' placeholder='Utiliza tu usuario de Retz' />
          <TextField onChange={(e) => setPassword(e.target.value)} sx={{ width: responsiveness('60%', '40%', '30%'), marginBottom: showError ? '1%' : '3%'}} id='pw' type='password' label='Contraseña' variant='outlined' InputLabelProps={{ shrink: true }} />
          {showError && (<p style={{ color: 'red', fontFamily: 'PublicSans-Normal'}}>Favor de revisar sus credenciales.</p>)}
          <Button onClick={handleLogin} sx={{ backgroundColor: '#514DD8', color: 'white', padding: '1% 5%', fontFamily: 'PublicSans-Bold', fontSize: '14px', '&:hover': { color: '#514DD8' }}}>Comenzar</Button>
      </Box>
    </div>
  );
};

export default Login;