import React from 'react';
import Logo from '../assets/logo.png'

const Navbar: React.FC = () => {

  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  return (
    <nav style={{
        backgroundColor: '#D9F27A',
        paddingLeft: '5%'
    }}>
      <div style={{ display: 'flex', justifyContent: 'start', paddingTop: responsiveness('2.5%', '2%', '1.25%'), paddingBottom: responsiveness('2.5%', '2%', '1.25%')}}>
        <a href="https://retz.mx/login/index.php"><img src={Logo} alt='' width={responsiveness(50, 55, 60)}/></a>
      </div>
    </nav>
  );
}

export default Navbar;