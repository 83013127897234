// L I B R A R I E S 
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Navigate } from 'react-router-dom';
import React from 'react';

// C O M P O N E N T S
import { User } from '../models/User';
import Navbar from './navbar';

// A S S E T S
import EnglishTeacherPhoto from '../assets/teacheringles.png';
import GermanTeacherPhoto from '../assets/teacheraleman.png';
import FrenchTeacherPhoto from '../assets/teacherfrances.png';
import Bot from '../assets/bot.png'
import '../App.css';

function LanguageHome() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser: User = location.state?.currentUser;
  const language: string = location.state?.language;

  if (!currentUser || !currentUser.isAuthenticated) {
    return <Navigate to="/login" />
  }

  const responsiveness = (small: any, medium: any, large: any) => {
    return window.innerWidth < 600 ? small : window.innerWidth < 900 ? medium : large;
  }

  const buttonsStyle = { fontSize: responsiveness('14px', '18px', '22px'), backgroundColor: '#514DD8', color: 'white', borderRadius: '8px', padding: responsiveness('2% 5%', '2% 5%', '2% 5%'), marginBottom: responsiveness('5%', '3%', '3%'), '&:hover': { color: '#514DD8', border: '#514DD8 solid 2px' }};
 
  return (
    <div>
      <div style={{
        position: 'fixed', 
        width: '100%',
        zIndex: 1
      }}>
        <Navbar />
      </div>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: responsiveness('15%', '10%', '5%')
      }}>
        <Box sx={{ width: '80%'}}>
          <p style={{ fontFamily: 'PublicSans-Bold', fontSize: responsiveness('25px', '30px', '40px')}}>Hola {currentUser.name}, soy Retzia, <img src={Bot} alt='' width={responsiveness(80, 100, 130)} height={responsiveness(60, 70, 90)} /> tu profesora virtual de {language === 'english' ? 'inglés' : language === 'german' ? 'alemán' : 'francés'}.</p>
          <p style={{ marginLeft: '1%', fontFamily: 'PublicSans-Normal', fontSize: responsiveness('22px', '28px', '32px')}}>Haz clic en la opción que desees realizar.</p>
          <Box sx={{ marginLeft: '1%', display: 'flex', flexDirection: 'column', width: responsiveness('90%', '90%', '60%'), marginTop: '3%', marginBottom: responsiveness('3%', '3%', '2%')}}>
            <Button 
            onClick={() => navigate('/chat', {state: {currentUser, language: language }})}
            sx={{...buttonsStyle}}>QUIERO PRACTICAR MI CONVERSACIÓN</Button>
            <Button
            onClick={() => navigate('/grammar', {state: {currentUser, language: language }})} 
            sx={{...buttonsStyle}}>TENGO UNA DUDA DE GRAMÁTICA O VOCABULARIO</Button>
            <Button
            onClick={() => navigate('/taskreviewer', {state: {currentUser, language: language }})} 
            sx={{...buttonsStyle}}>REALIZAR ACTIVIDAD DEL CURSO</Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 3, alignItems: 'center'}}>
            <Box sx={{ flex: 1 }}>
              <p style={{ fontFamily: 'PublicSans-Bold', fontSize: responsiveness('16px', '24px', '34px')}}>
                ¿No encuentras lo que necesitas? Ponte en contacto con tu {language === 'english' ? 'profesora de inglés' : language === 'german' ? 'profesor de alemán' : 'profesora de francés'}.
              </p>
              <Button 
              onClick={() => {
                const url = language === 'english' ? 'https://retz.mx/user/profile.php?id=202' 
                : language === 'german' ? 'https://retz.mx/user/profile.php?id=138' 
                : 'https://retz.mx/user/profile.php?id=1865';
                window.open(url, '_blank', 'noopener,noreferrer');
              }}
              sx={{...buttonsStyle}}>
                {language === 'german' ? 'CONTACTAR PROFESOR' : 'CONTACTAR PROFESORA'}
              </Button>
            </Box>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', flex: 1}}>
              <a 
              href={ language === 'english' ? 'https://wa.me/528116063009?text=%C2%A1Hola!%20Quisiera%20hablar%20con%20mi%20teacher..' 
                : language === 'german' ? 'https://wa.me/528116063009?text=%C2%A1Hola!%20Quisiera%20hablar%20con%20mi%20Lehrer..' 
                : 'https://wa.me/528116063009?text=%C2%A1Hola!%20Quisiera%20hablar%20con%20mi%20professeur..' } 
              target="_blank" 
              rel="noopener noreferrer">
                <img src={ language === 'english' ? EnglishTeacherPhoto : language === 'german' ? GermanTeacherPhoto : FrenchTeacherPhoto } alt='' width={responsiveness(200, 300, 400)}></img>
              </a>
              <p style={{ fontFamily: 'PublicSans-Normal', fontSize: responsiveness('12px', '16px', '18px')}}>
                {language === 'english' ? 'Ana Paula de la Torre' : language === 'german' ? 'Robert Retzinger' : 'Gabriela Cámara'}
              </p>
              <p style={{ fontFamily: 'PublicSans-Normal', fontSize: responsiveness('12px', '16px', '18px'), marginTop: '-2%'}}>
                {language === 'english' ? 'English Teacher' : language === 'german' ? 'Deutsch Lehrer' : 'Professeur de Français'}
              </p>
            </Box>
          </Box>
          
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '1%'}}>
      </Box>
    </div>
  );
};

export default LanguageHome;
